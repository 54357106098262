import React, { useState, useEffect } from 'react';
import {useLocation, Link } from 'react-router-dom';
import Layout from './Layout';
import { Typography, Box, Button, CircularProgress} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark'; 

function HomePage() {
  const location = useLocation();
  const [bills, setBills] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [bookmarkedBills, setBookmarkedBills] = useState({}); 
  const [loading, setLoading] = useState(false);
  const userId = sessionStorage.getItem('user_id');

  const handleSearch = async (page) => {
    setLoading(true);
    try {
      const params = new URLSearchParams(location.search);
      const query = params.get('q') || '';
      const tracked = params.get('tracked');
      setSearchQuery(query || ''); 

      let apiUrl = `${process.env.REACT_APP_API_URL}/bills/search/${userId}/?q=${query}&page=${page}`;
    
      if (tracked) {
        apiUrl += `&tracked=${tracked}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setBills(data.bills || []);
      setCurrentPage(data.page);
      setTotalPages(data.pages);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch(currentPage);
  }, [location.search, currentPage]);

  const handlePageChange = (newPage) => {
    setLoading(true);
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setLoading(false);
    }
  };

  const handleBookmarkClick = async (billId) => {
    try {
      const billIndex = bills.findIndex(bill => bill.id === billId);
      if (billIndex === -1) {
        throw new Error('Bill not found');
      }

      const isBookmarked = bills[billIndex].tracking === 1;
      const action = isBookmarked ? 'delete' : 'add';
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-user-bill/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({ bill_id: billId, user_id: userId, action: action }), // Include user_id and action in the request body
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log(`Bill ${action}ed successfully:`, data);
  
      // Update the bill's tracking status in the state
      const updatedBills = [...bills];
      updatedBills[billIndex] = {
        ...updatedBills[billIndex],
        tracking: isBookmarked ? 0 : 1,
      };
      setBills(updatedBills);
      //setCurrentPage(response.data.page);
      //setTotalPages(response.data.pages);
    } catch (error) {
      //console.error(`Error ${action}ing bill:`, error);
    }
  };

  return ( 
    loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <CircularProgress />
      </Box>
    ) : (
    <Layout searchQuery={searchQuery}>
      <Box
        sx={{
          overflowY: 'auto', // Enable vertical scrolling
          padding: 2,
          width: '100%', // Full viewport width
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {bills.length === 0 && !loading ? (
          <Typography>No results found</Typography>
        ) : (
          bills.map(bill => (
          <React.Fragment key={bill.id}>
            <Box
              sx={{
                backgroundColor: '#F4F4F4',
                borderRadius: 2,
                padding: 2,
                maxWidth: 600,
                marginBottom: 2, // Add some space between boxes
              }}
            >
              <Link to={`/bill/${bill.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography>
                  {bill.tracking === 1 ? (
                    <BookmarkIcon
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        handleBookmarkClick(bill.id);
                      }}
                      style={{ verticalAlign: 'middle', marginRight: 8, cursor: 'pointer' }}
                    />
                  ) : (
                    <BookmarkBorderIcon
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        handleBookmarkClick(bill.id);
                      }}
                      style={{ verticalAlign: 'middle', marginRight: 8, cursor: 'pointer' }}
                    />
                  )}

                  {`${bill.state} ${bill.bill_number}${bill.title ? ` - ${bill.title}` : ''}`}
                  
                  {bill.status && (
                    <Typography sx={{ marginTop: 1 }}>
                      Status: {bill.status}
                    </Typography>
                  )}
                  
                  {bill.controversy_level && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                      <Typography sx={{ marginRight: 1 }}>
                        Controversy:
                      </Typography>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <img
                          key={index}
                          src={index < Math.floor(bill.controversy_level / 2) ? '/images/fire-on.svg' : '/images/fire-off.svg'}
                          alt="Fire"
                          style={{ width: 20, height: 20, marginRight: 4 }}
                        />
                      ))}
                    </Box>
                  )}
                  
                  {bill.sponsors && bill.sponsors.length > 0 && (
                    <Typography sx={{ marginTop: 1 }}>
                      Sponsor(s): {bill.sponsors.map(sponsor => sponsor.name).join(', ')}
                    </Typography>
                  )}

                  {bill.category && bill.category.length > 0 && (
                    <Typography sx={{ marginTop: 1 }}>
                      Tag(s): {bill.category}
                    </Typography>
                  )}

                </Typography>
              </Link>
            </Box>
            <Box sx={{ height: 16 }} /> {/* Spacer element */}
          </React.Fragment>
        ))
      )}

        <Box display="flex" justifyContent="left" alignItems="left" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ marginRight: 2 }}
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            sx={{ marginLeft: 2 }}
          > 
            Next
          </Button>
        </Box>
      </Box>
    </Layout>
  ));
}

export default HomePage;