import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Avatar, Typography, TextField, Button, Grid, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function LoginPage() {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if login data is already in session storage
    const savedLoginData = sessionStorage.getItem('loginData');
    if (savedLoginData) {
      // Redirect to the home page if already logged in
      navigate('/home');
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData = {
      email: data.get('email'),
      password: data.get('password'),
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Corrected header
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`, // Corrected header
        },
        body: JSON.stringify(loginData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      sessionStorage.setItem('loginData', JSON.stringify(result.user));
      sessionStorage.setItem('user_id', result.user.id);
      console.log('Success:', result);
      navigate('/home'); // Redirect to home page on successful login
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LoginPage;