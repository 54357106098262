import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import BillPage from './BillPage';
import ChatPage from './ChatPage';

const theme = createTheme();

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/bill/:id" element={<BillPage />} />
            <Route path="/chat" element={<ChatPage />} />
          </Routes>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;