import React, { useState, useEffect, useRef } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

function TextTab({ id }) {
  const [textData, setTextData] = useState([]);
  const [isTextDataFetched, setIsTextDataFetched] = useState(false);
  const [billData, setBillData] = useState([]);
  const [selectedTextId, setSelectedTextId] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [messages, setMessages] = useState([]);
  const iframeRef = useRef(null);
  const messageIds = [];
  const userId = sessionStorage.getItem('user_id');

  useEffect(() => {
    let isMounted = true;
    
    if (!isTextDataFetched) {
      fetch(`${process.env.REACT_APP_API_URL}/bill-text/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (isMounted) {
            setTextData(data.text);
            setBillData(data.bill);
            setIsTextDataFetched(true);
            if (data.text.length > 0) {
              setSelectedTextId(data.text[0].id);
              setFileUrl(`https://api.civiclyenvolved.com/bill-text/${data.text[0].id}/`)
            }
          }
        })
        .catch(error => console.error('Error fetching text data:', error));

        fetch(`${process.env.REACT_APP_API_URL}/user-chat/${userId}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            data.forEach(item => {
              if (messageIds.includes(item.id)) {
                return;
              }

              console.log(data);
              setMessages(prevMessages => [...prevMessages, { content: item.question_text, direction: 'outgoing' }]);
              setMessages(prevMessages => [...prevMessages, { content: item.answer_text, direction: 'incoming', original: item.original_text }]);
              messageIds.push(item.id);
            });
          })
          .catch(error => console.error('Error fetching user chat data:', error));
    }

    return () => {
      isMounted = false;
    };
  }, [id, isTextDataFetched]);

  const handleTextChange = (event) => {
    const selectedId = event.target.value;
    setSelectedTextId(selectedId);

    const selectedText = textData.find(text => text.id === selectedId);
    if (selectedText) {
      setFileUrl(selectedText.file_url);
    }
  };

  const updateIframeUrl = (iframeRef, fileUrl, originalText) => {
    if (iframeRef.current) {
      const newUrl = new URL(fileUrl);
      newUrl.searchParams.set('q', originalText);
      iframeRef.current.src = newUrl.toString();
    }
  };

  const handleSendMessage = (message) => {
    setMessages([...messages, { content: message, direction: 'outgoing' }]);

    const thinkingMessage = { content: "We're thinking...", direction: 'incoming' };
    setMessages(prevMessages => [...prevMessages, thinkingMessage]);    
    
    fetch(`${process.env.REACT_APP_API_URL}/chat/${id}?q=${encodeURIComponent(message)}&user_id=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        
        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.indexOf(thinkingMessage)] = { content: data.answer, direction: 'incoming', original: data.original_text };
          return newMessages;
        });

        // Update iframe URL with query parameter
        updateIframeUrl(iframeRef, fileUrl, data.original_text);

      })
      .catch(error => console.error('Error fetching reply:', error));
  };

  return (
    <Box>
      {billData && (
        <Box sx={{ marginBottom: 5 }}>
          {billData.description && billData.description.length > 0 && (
            <>
              <Typography variant="h6">Description</Typography>
              <Typography variant="body1">{billData.description}</Typography>
            </>
          )}
          {billData.policy_objective && billData.policy_objective.length > 0 && (
            <>
              <Typography variant="h6" sx={{ marginTop: 2 }}>InformNation Summary</Typography>
              <Typography variant="body1">{billData.policy_objective}</Typography>
            </>
          )}
        </Box>
      )}

      <FormControl fullWidth>
        <InputLabel id="text-select-label">Select Text</InputLabel>
        <Select
          sx={{ width: '30vw' }}
          labelId="text-select-label"
          value={selectedTextId}
          label="Select Text"
          onChange={handleTextChange}
        >
          {textData.map((text) => (
            <MenuItem key={text.id} value={text.id}>
              {text.status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box display="flex" alignItems="flex-start" sx={{ marginTop: 2 }}>
        {fileUrl && (
          <Box flex={1}>
            <iframe ref={iframeRef} src={fileUrl} width="100%" height="600px" title="Bill Text" style={{ border: '1px solid #E0E0E0' }} />
          </Box>
        )}

        <Box width="400px" height="600px" marginLeft="16px">
          <MainContainer>
            <ChatContainer>
              <MessageList>
                {messages.map((message, index) => (
                  <Tooltip title={message.original || ''} key={index}>
                    <div>
                      <Message
                        model={{
                          message: message.content,
                          sentTime: 'just now',
                          sender: 'User',
                          direction: message.direction,
                        }}
                        onClick={() => updateIframeUrl(iframeRef, fileUrl, message.original || '')}
                      />
                    </div>
                  </Tooltip>
                ))}
              </MessageList>
              <MessageInput
                placeholder="Type a message..."
                onSend={handleSendMessage}
                attachButton={false}
              />
            </ChatContainer>
          </MainContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default TextTab;